header{
    min-height: 600px;
    background-size: cover;
    background-repeat: no-repeat;
}

header > div{
    background-color: rgba(0, 0, 0, .7);
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
}

header .detalles{
    flex-wrap: nowrap;
}


.white-hide{
    color: #ffffff;
    font-weight: 400;
}