.landing p{
    font-size: 17px;
    letter-spacing: 1;
    font-weight: normal;
}

.landing .t2{
    font-size: 35px;
    line-height: 37px;
    font-weight: 600;
    border-right: solid 4px var(--pink);
    margin-bottom: 30px;
    padding-right: 30px;
}

.landing ul{
    margin-left: 20px;
}

.landing a{
    font-size: inherit;
    color: var(--primary);
    transition: all ease .3s;
}
.landing a:hover{
    text-decoration: underline;
}

.landing ul{
    list-style: circle;
}

.landing ul > li{
    font-size: 17px;
    line-height: 35px;
}

.landing .text-right{
    text-align: right;
}
