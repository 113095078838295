#menu {
	position: fixed;
	right: -250px;
	top: 0%;
	z-index: 2;
	background-color: #131313;
	height: 100%;
	width: 250px;
	transition: all ease 0.3s;
	padding: 20px;
}

#menu .info {
	padding: 10px;
	border-radius: 20px;
	background-color: #29292950;
}

#menu.active {
	right: 0 !important;
}

.sombra {
	background-color: rgba(235, 33, 101, 0.3);
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1;
	opacity: 0;
	visibility: hidden;
	transition: all ease 0.3s;
	cursor: pointer;
}

.sombra.active {
	visibility: visible !important;
	opacity: 1 !important;
}

#menu ul li a {
	padding: 10px;
	font-weight: 400;
	display: block;
	border-bottom: solid 1px #13131340;
	border-left: solid 2px #292929;
	margin-bottom: 5px;
}
