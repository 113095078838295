.submenu {
	background-color: var(--primary);
	color: #fff;
	margin-bottom: 20px;
}

.submenu .buttons {
	display: inline-block;
	padding: 10px 15px;
	border-right: solid 1px #f9f9f930;
	text-transform: capitalize;
	font-weight: 500;
}

.submenu .buttons:nth-child(1) {
	padding-left: 0 !important;
}
