#formcontact{
    width: 100%;
    max-width: 800px;
    height: 500px;
    border-radius: 6px;
    background-color: #272a33;
    margin: 100px auto;
    overflow: hidden;
}


#formcontact input, #formcontact textarea{
    border: 0;
    outline: none;
    background-color: transparent !important;
    color: #fff;
    border-bottom: solid 1px #ffffff20;
    height: 35px;
    width: 100%;
}

#formcontact > div{
    padding: 40px;
    height: 100%;
    min-width: 300px
}

#formcontact input::placeholder{
    color: #ffffff20;
}

#formcontact .title{
    font-size: 16px;
}


#formcontact #lista > li{
    margin-bottom: 20px;
    color: #767676;
    vertical-align: middle;
}

#formcontact #lista > li  span{
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(250, 250, 250, .06);
    border-radius: 100%;
    margin-right: 10px;
    vertical-align: middle;
}

#formcontact #lista > li  span > ion-icon{
    color: #fff;
    font-size: 18px !important;
}

#formcontact #lista > li  strong{
    font-weight: 500;
    color: #fff !important;
    vertical-align: middle;
    margin-right: 10px;
}

@media only screen and (max-width: 600px) {
    #formcontact{
        height: auto;
    }
}