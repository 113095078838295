.btnnuevo .despliegue {
	position: absolute;
	right: 0;
	top: calc(100% + 20px);
	width: 250px;
	background-color: #ffff;
	border-radius: 5px;
	color: #292929;
	padding: 10px;
	text-align: right;
	opacity: 0;
	visibility: hidden;
	z-index: 100;
}

.btnnuevo .despliegue a {
	display: inline-block;
	text-align: left;
	font-size: 16px;
	font-weight: 500;
	border-bottom: solid 1px #f9f9f9;
	padding: 10px;
}

.btnnuevo .despliegue.active {
	opacity: 1;
	visibility: visible;
}
