:root {
	--blue: #007bff;
	--indigo: #6610f2;
	--purple: #6f42c1;
	--pink: #ff3850;
	--red: #dc3545;
	--orange: #fd7e14;
	--yellow: #ffc107;
	--green: #28a745;
	--teal: #20c997;
	--cyan: #17a2b8;
	--white: #fff;
	--gray: #6c757d;
	--gray-dark: #343a40;
	--primary: #00aae7;
	--secondary: #6c757d;
	--success: #28a745;
	--info: #17a2b8;
	--warning: #ffc107;
	--danger: #dc3545;
	--light: #f8f9fa;
	--dark: #343a40;
}

.pth {
	padding-top: 80px !important;
}

#root {
	display: flex;
	flex-flow: column nowrap !important;
	height: 100vh;
}

#root > * {
	flex: 1;
}

nav {
	padding: 10px 0;
	position: fixed;
	left: 0%;
	top: 0%;
	z-index: 1;
	width: 100%;
	transition: all ease 0.3s;
}

nav.active {
	background-color: #292929;
}

body {
	background-color: #131313;
	color: #fff;
}

/* ESTILOS GENERALES DEL SISTEMA */
* {
	font-family: 'poppins', 'sans-serif', 'arial';
	box-sizing: border-box;
	position: relative;
	color: inherit;
}

/* MANEJO DE IMAGENES SEGUN CARGA */

img[data-original] {
	opacity: 0;
}

img {
	opacity: 1;
	transition: opacity 0.3s;
}

.article-h {
	padding: 10px;
	border-radius: 10px;
	background-color: #fff;
	margin-bottom: 10px;
	border-bottom: solid 1px #ddd;
}

.coverfoto {
	width: 35px;
	height: 35px;
	border-radius: 100%;
	overflow: hidden;
}

.coverfoto > img {
	object-fit: cover;
	width: 100%;
	min-height: 100%;
}

/* SCROLL BAR ESTILOS*/

::-webkit-scrollbar {
	background-color: #fff;
	width: 8px !important;
}

::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	color: red;
}

/* ELEMENTOS GENERALES Y ESTILOS DE FABRICA */

body,
html,
form {
	font-size: 12px !important;
	margin: 0;
	padding: 0;
}

h1,
h2,
h3,
h4 {
	margin: 0;
}

.t1 {
	font-size: 57px;
	line-height: 63px;
}

a {
	text-decoration: none;
	font-size: inherit;
	cursor: pointer;
}

span.a {
	cursor: pointer;
}

span.a:hover {
	border-bottom: solid 1.8px currentColor;
}

p {
	padding: 0;
	margin: 0;
}

i {
	font-size: inherit !important;
}

.underline {
	text-decoration: underline;
}

.no-margin {
	margin: 0;
}

ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

table {
	border-collapse: collapse;
	width: 100%;
}

.main {
	width: 90%;
	max-width: 1100px;
	margin: 0 auto;
}

.main-90 {
	width: calc(100% - 40px);
	margin: 0 auto;
}

.main-auto {
	margin: 0 auto;
}

/* FLEXBOX SITIO WEB RESPONSIVE */

.flex {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
}

.flex > div {
	flex: 1;
}

.grid {
	--minimumColumnWidth: 90px;
	grid-gap: 10px;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(var(--minimumColumnWidth), 1fr));
}

.grid > div {
	width: 100px;
	height: 100px;
}

.aling-top {
	align-items: flex-start;
}

.flex-column {
	display: flex;
	flex-flow: column nowrap !important;
}

.flex-column > div {
	flex: 1;
}

.initial {
	flex: initial !important;
}

.block {
	display: block !important;
}

.display-none {
	display: none;
}

.hidden {
	overflow: hidden;
}

.scroll-y {
	overflow-y: auto;
}

.scroll-x {
	overflow-x: auto;
}

.sticky {
	position: sticky !important;
}

.shadow {
	box-shadow: 0 3px 3px 0 rgba(21, 27, 38, 0.15);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	/*border: 1px solid green;*/
	/*-webkit-text-fill-color: green;*/
	/*-webkit-box-shadow: 0 0 0px 1000px #000 inset;*/
	transition: background-color 5000s ease-in-out 0s;
}

/*VENTANA MODAL*/

#modal {
	display: none;
	opacity: 0;
	justify-content: center;
	align-items: center;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 5;
	background-color: rgba(0, 0, 0, 0.7);
	/*background-color: rgba(39,50,64,.6);*/
}

#modal > .caja {
	display: flex;
	max-width: 90%;
	height: auto;
	max-height: 95vh;
	border-radius: 3px;
	position: relative;
	/*box-shadow: 0 2px 3px rgba(0,0,0,.1);*/
}

.bloque {
	padding: 15px;
	background-color: #fff;
	border-radius: 10px;
}

#conexion {
	display: none;
	width: 100%;
	height: 100%;
	z-index: 5;
	background-color: rgba(39, 50, 64, 0.6);
	position: fixed;
	left: 0;
	top: 0;
}

#conexion .bloque2 {
	background-color: #fff;
	padding: 10px;
}

/*FUENTES DE TEXTOS*/

.bold {
	font-weight: bold;
}

.light {
	font-weight: lighter;
}

.bold-400 {
	font-weight: 500;
}

.bold-500 {
	font-weight: 500;
}

.lt-text {
	font-size: 11px;
}

.uppercase {
	text-transform: uppercase;
}

.capitalize {
	text-transform: capitalize;
}

.relative {
	position: relative;
}

.center {
	text-align: center;
}

.right {
	text-align: right;
}

.left {
	text-align: left;
}

.centro {
	display: flex;
	justify-content: center;
	align-items: center;
}

.lh14 {
	line-height: 14px;
}

.minleng {
	color: #8694a3;
}

.text-primary {
	color: var(--primary);
}

.text-pink {
	color: var(--pink);
}

.text-red {
	color: var(--red);
}

.text-success {
	color: var(--success);
}

.text-warning {
	color: var(--orange);
}

.text-white {
	color: #fff;
}

/*MARGENES Y ESPACIOS*/
.padding-l {
	padding: 10px;
}

.padding-m {
	padding: 20px;
}

.padding-b {
	padding: 40px;
}

.padding-v {
	padding: 20px 0;
}

.padding-v-m {
	padding: 10px 0;
}

.padding-v-b {
	padding: 60px 0;
}

.padding-h {
	padding: 0 20px;
}

.padding-h-m {
	padding: 0 20px;
}

.margin-top {
	margin-top: 20px;
}

.margin-top-l {
	margin-top: 10px;
}

.margin-bottom {
	margin-bottom: 20px;
}

.margin-bottom-l {
	margin-bottom: 10px;
}

.mr-10 {
	margin-right: 10px;
}

.mr-20 {
	margin-right: 20px;
}

.mr-15 {
	margin-right: 15px;
}

.mb-10 {
	margin-bottom: 10px;
}

.mb-15 {
	margin-bottom: 15px;
}

.mb-20 {
	margin-bottom: 20px;
}

.margin-left {
	margin-left: 20px;
}

.margin-right {
	margin-right: 10px;
}

.margin-v {
	margin: 20px 0;
}

.size-14 {
	font-size: 14px;
}

/* BOTONES */

.btn {
	border: 0px solid transparent;
	outline: 0 !important;
	white-space: nowrap;
	vertical-align: middle;
	padding: 4px 10px;
	min-width: 100px;
	font-size: 12px;
	text-align: center;
	display: inline-block;
	border-radius: 6px;
	cursor: pointer;
	font-weight: 500;
	transition: all ease 0.3s;
	vertical-align: middle;
}

.btn:hover:after {
	content: '';
	background-color: #00000010;
	position: absolute;
	left: 0%;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	border-radius: inherit;
}

.btn > span {
	padding: 0 4px;
	border-radius: 2px;
	background-color: #ffffff30;
	margin-right: 5px;
	font-size: 8px;
	line-height: 8px;
	vertical-align: middle;
	font-weight: 600;
}

.btn-radio {
	border-radius: 25px;
}

.btn-primary {
	background-color: rgb(33, 132, 190) !important;
	border-color: #007bff;
	color: #fff;
}

.btn-red {
	background-color: var(--red) !important;
	border-color: #007bff;
	color: #fff;
}

.btn-primary-flat {
	background-color: transparent !important;
	border: solid 1px var(--primary);
	color: var(--primary);
}

.btn-primary-flat:hover {
	background-color: var(--primary) !important;
	color: #fff;
}

.btn-white-flat {
	background-color: rgba(250, 250, 250, 0.2) !important;
	border: solid 2px #fff;
	color: #fff;
}

.btn-facebook {
	background-color: #3b5998;
	color: #fff;
	padding: 13px 20px;
	border-radius: 4px;
	width: 100%;
	display: inline-block;
	text-align: left;
}

.btn-google {
	background-color: #fff;
	color: gray;
	padding: 8px 10px;
	border-radius: 4px;
	width: 100%;
	display: inline-block;
	text-align: left;
	border: solid 2px #ddd;
}

/*LOADING FOR WEB*/
#loadingweb {
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(250, 250, 250, 0.4);
	z-index: 20;
	justify-content: center;
	align-items: center;
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
}

#loading {
	padding: 10px;
	background-color: transparent;
	width: 30px;
	height: 30px;
	display: inline-block;
	vertical-align: middle;
	border: solid #e6e6e6 4px;
	border-top: 4px solid #0176fe;
	border-radius: 100%;
	animation-name: rotate;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	animation-fill-mode: forwards;
}

#loading2 {
	background-color: transparent;
	width: 20px;
	height: 20px;
	display: inline-block;
	opacity: 1;
	vertical-align: middle;
	border: solid #e6e6e6 2px;
	border-top: 2px solid #0176fe;
	border-radius: 100%;
	animation-name: rotate;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	animation-fill-mode: forwards;
}

footer {
	padding: 10px;
	color: #fff;
}

footer .icono {
	width: 30px;
	height: 30px;
	background-color: rgba(255, 255, 255, 0.05);
	border-radius: 4px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin-right: 5px;
}

.campo {
	width: 100%;
	background-color: #292929;
	border-radius: 5px;
	height: 35px;
}

.campo > input {
	width: 100%;
	background-color: transparent;
	outline: none;
	border: 0ch;
	height: 100%;
	padding: 0 10px;
}

.campo > input::placeholder {
	color: #ffffff20;
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
