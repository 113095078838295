@media only screen and (max-width: 600px) {
    .m-none{
        display: none !important;
    }

    .main{
        padding: 40px 10px !important;
        width: 100%;
        margin: 0;
        padding: 0 20px !important;
    }
}