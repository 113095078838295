.experiencia{
    padding: 20px;
    transition: all ease .3s;
    border-bottom: solid 1px #ffffff10;
    border-radius: 3px;
    color: #fff !important;
    text-decoration: none !important;
}

.experiencia:hover{
    background-color: #ffffff10;
}